<template>
  <div class="register_bg" :class="newTheme">
    <!-- 预留的验证码页面元素，用于配置初始化函数中的element参数 -->
    <div id="captcha-element"></div>
    <div id="registerEle"></div>
    <div @keyup.enter="enterHandler" class="register-flexible-wrapper">
      <header :class="newTheme == 'black' ? 'theme-black header' : 'header'">
        <div class="nav" style="min-width:auto">
          <div class="logo-title">
            <nuxt-link :to="$i18n.path('')" style="height:20px;">
              <img class="logo-weex" :src="logoSrc" />
            </nuxt-link>
          </div>
          <!-- 多语言 -->
          <language-list />
        </div>
      </header>
      <div :class="isFixed ? 'main-body w1000' : 'main-body'">
        <div class="left-part-main" v-if="regStep !== 3" :class="{ en: locale == 'en' }">
          <!-- <LeftCarouselImg :type="2" :vipCodeStr="vipCodeStr" /> -->
          <LeftCarouselImg :type="2" />
          <!-- 不傳入vipCodeStr,在組件中直接從url獲取 -->
        </div>
        <div :style="fixedPosition" :class="isFixed?'right-part-main fixed-element':'right-part-main'" v-if="regStep !== 3">
          <div class="right-part-top">
            <!-- 第一步： -->
            <div class="register-step__1" v-if="regStep === 1">
              <h1 v-if="locale != 'ko'" class="register-step-title">
                {{ $t('register.title') }}
                <span class="yellow">WEEX</span>
              </h1>
              <h1 v-else class="register-step-title">
                <span class="yellow">WEEX</span>
                {{ $t('register.title') }}
              </h1>
              <!-- 手机/邮箱切换 -->
              <div class="type-tab clearfix">
                <span :class="{ active: typeTab === 2 }" @click="setTypeTab(2)">{{ $t('register.tab_email') }}</span>
                <span class="type-tab-split"></span>
                <span :class="{ active: typeTab === 1 }" @click="setTypeTab(1)">{{ $t('register.tab_phone') }}</span>
                <!-- 活動連結 -->
                <img
                  :class="{ fire_icon: getLang == 'zh-CN' || 'zh-TW', fire_icon_en: getLang == 'en' }"
                  src="~/assets/img/public/fire_icon.png"
                />
                <span
                  :class="{
                    event_title: getLang == 'zh-CN' || 'zh-TW',
                    event_title_en: getLang == 'en'
                  }"
                  @click="gotoEvent()"
                >
                  {{ $t('register.event') }}
                </span>
              </div>
              <div
                :class="[
                  'register-account-row',
                  this.errorPhone ? 'isError' : '',
                  this.focusPhoneInput ? 'isActive' : ''
                ]"
                v-if="typeTab === 1"
                style="position: relative"
                @focus="handlePhoneFocus"
              >
                <div :class="['area-code', this.focusPhoneInput ? 'focus' : '']">
                  <div class="area-code__display" @click="showSelAreaDialog">
                    + {{ areaCode }}
                    <img class="icon_down" src="~assets/img/public/icon_down2.svg" alt="" />
                  </div>
                  <!-- 区号列表 -->
                  <!--                <area-phone-->
                  <!--                  class="area-code__list"-->
                  <!--                  :open-search="true"-->
                  <!--                  v-clickoutside="hideAreaList"-->
                  <!--                  :areaCode.sync="areaCode"-->
                  <!--                  v-show="showAreaCode"-->
                  <!--                  @selected="selectAreaCode"-->
                  <!--                ></area-phone>-->
                  <div class="area-code-list" v-show="isShowSelAreaDialog">
                    <SelAreaCode v-if="ifSelArea" @selArea="selArea" />
                  </div>
                  <div class="code_line"></div>
                </div>
                <!-- <div class="code_line"></div> -->
                <!--              <el-dialog-->
                <!--                title=""-->
                <!--                :visible.sync="isShowSelAreaDialog"-->
                <!--                width="30%">-->
                <!--                <SelAreaCode v-if="ifSelArea" @selArea="selArea"/>-->
                <!--              </el-dialog>-->
                <!--              <area-code -->
                <!--                :styelData='{width:"20%",height:"50px",borderRadius:"6px",borderColor: "#eaecef"}'-->
                <!--                :listStyle='{width:"370px",height:"400px",zIndex:"9999",position:"absolute",top:"51px"}'-->
                <!--              ></area-code>-->
                <register-input
                  class="phoneInput"
                  v-model="iptPhone"
                  :label="$t('register.placeholder_phone')"
                  @focus="handlePhoneFocus"
                  @blur="blurPhone"
                  :error="errorPhone"
                />
                <img
                  class="clear"
                  src="~assets/img/public/clear.svg"
                  alt=""
                  v-show="phoneClearIcon"
                  @click="clearPhone"
                />
              </div>

              <p v-show="typeTab === 1" class="overseas-sms-msg">{{ overseasSmsMsg }}</p>

              <div :class="['register-account-row', this.errorEmail ? 'isError' : '']" v-if="typeTab === 2">
                <register-input
                  v-model="iptEmail"
                  :label="$t('register.placeholder_email')"
                  @focus="focusEmail"
                  @blur="blurEmail"
                  :error="errorEmail"
                />
                <img
                  class="clear"
                  src="~assets/img/public/clear.svg"
                  alt=""
                  v-show="emailClearIcon"
                  @click="clearEmail"
                />
              </div>
              <div class="common-email-postfix">
                <div
                  v-show="checkPostFixShow(iptEmail, item)"
                  v-for="item in postfixList"
                  :key="item"
                  class="item"
                  @click="selectPostfix(item)"
                >
                  <span class="input">{{ getValidIptMail(iptEmail) }}</span> {{ item }}
                </div>
              </div>
              <!-- 設置密碼 -->
              <!-- <p class="confirmPwd">{{ $t("expressly.pwd") }}</p> -->
              <div :class="['password-input__row', this.errorPwd != '' ? 'error_hint' : '']">
                <register-input
                  :type="pwdTypeStr"
                  v-model="iptPwd"
                  @focus="handlePwdFocus"
                  @blur="blurPwd"
                  :label="$t('expressly.set_pwd')"
                  :error="errorPwd"
                />
                <div class="password-input-suffix">
                  <img
                    class="clear"
                    src="~assets/img/public/clear.svg"
                    alt=""
                    v-show="textClearIcon1"
                    @click="clearPwd"
                  />
                  <div class="iconImg eyeclose" :class="{ eyeopen: pwdEye }" @click="pwdEye = !pwdEye"></div>
                </div>
                <div v-show="pwdRuleShow" class="password-input__tip">
                  <!-- <div>* {{ $t('register.placeholder_pwd') }}</div>
                  <div class="tip2" v-html="$t('register.special_char')"></div> -->
                  <div><span class="dot" :class="{ green: pwdRule[0] }"></span>{{ $t('register.pwd_rule1') }}</div>
                  <div><span class="dot" :class="{ green: pwdRule[1] }"></span>{{ $t('register.pwd_rule2') }}</div>
                  <div><span class="dot" :class="{ green: pwdRule[2] }"></span>{{ $t('register.pwd_rule3') }}</div>
                  <div><span class="dot" :class="{ green: pwdRule[3] }"></span>{{ $t('register.pwd_rule4') }}</div>
                  <div class="tip2" v-html="$t('register.special_char')"></div>
                </div>
              </div>
              <!-- 確認密碼 -->
              <!-- <p class="confirmPwd">{{ $t("expressly.confirm_pwd") }}</p> -->
              <div v-if="false" :class="['password-input__row', this.errorPwdConfirm != '' ? 'error_hint2' : '']">
                <register-input
                  :type="pwdConfirm"
                  :label="$t('expressly.confirm_pwd')"
                  v-model="iptPwdConfirm"
                  @blur="blurPwdConfirm"
                  :error="errorPwdConfirm"
                />
                <div class="password-input-suffix">
                  <img
                    class="clear"
                    src="~assets/img/public/clear.svg"
                    alt=""
                    v-show="textClearIcon"
                    @click="clearText"
                  />
                  <div class="iconImg eyeclose" :class="{ eyeopen: pwdEye2 }" @click="pwdEye2 = !pwdEye2"></div>
                </div>
              </div>

              <!-- 邀請碼(VIP code) -->
              <div class="vipCodeTitle">
                {{ $t('register.vip_code') }}
                <img
                  v-show="!vipCodeShow"
                  src="~assets/img/public/icon_down.svg"
                  alt=""
                  @click="vipCodeShow = !vipCodeShow"
                />
                <img
                  v-show="vipCodeShow"
                  src="~assets/img/public/icon_up.svg"
                  alt=""
                  @click="vipCodeShow = !vipCodeShow"
                />
              </div>
              <div v-show="vipCodeShow" class="vip-code">
                <register-input
                  :label="$t('register.placeholder_code')"
                  v-model="vipCodeStr"
                  @blur="blurVip"
                  :error="errorVip"
                />
                <div class="vip-input-suffix">
                  <img
                    src="~assets/img/public/clear.svg"
                    alt=""
                    style="width:14px"
                    v-show="vipClearIcon"
                    @click="clearVip"
                  />
                </div>
              </div>

              <!-- 协议 -->
              <div class="reg-protocol clearfix">
                <check-style-new
                  class="check-protocol"
                  :isChecked.sync="checked"
                  @click="checked = !checked"
                ></check-style-new>
                {{ $t('register.reg_read') }}&nbsp;
                <a target="_blank" rel="nofollow" :href="$t('register.reg_protocol_link')">{{
                  $t('register.reg_protocol')
                }}</a>
              </div>
              <div class="reg-from-error">
                <v-errtip :error="errorProtocol"></v-errtip>
              </div>
              <!-- 去第二步 -->
              <div class="register_btn_wrap">
                <el-button
                  class="reg-btn"
                  type="yellow"
                  @click="goSecondStep"
                  :loading="btnLoading"
                  :disabled="btnDisabled"
                  >{{ $t('register.btn_reg_new') }}
                </el-button>
                <!-- 活动提示 -->
                <!--                <div v-if="locale=='zh-CN'||locale=='zh-TW'" class="re_activity_wrap">-->
                <!--                  <img class="libao_wrap" src="~/assets/img/public/icon_libao.svg" alt=""> {{ $t('register.re_activity_text') }}-->
                <!--                </div>-->
              </div>
              <div class="thirdParty-register">
                <div class="title">
                  <div class="line"></div>
                  <div class="sub-tit">{{ $t('register.3rd-party') }}</div>
                  <div class="line"></div>
                </div>
                <div class="btn-wrap">
                  <div class="btn">
                    <img class="icon google" src="~/assets/img/public/icon_google.svg" />
                    <div class="title">Google</div>
                  </div>
                  <div class="btn">
                    <img v-show="newTheme == 'black'" class="icon apple" src="~/assets/img/public/icon_ios.svg" />
                    <img v-show="newTheme == 'white'" class="icon apple" src="~/assets/img/public/icon_ios_b.svg" />
                    <div class="title">Apple</div>
                  </div>
                </div>
              </div>
              <div class="go-login">
                {{ $t('register.account_got') }}
                <nuxt-link :to="loginLink">{{ $t('register.login') }}</nuxt-link>
              </div>
            </div>
            <!-- 第二部：验证码 -->
            <div :class="`register-step__2 ${newTheme}`" v-if="regStep === 2">
              <div class="reg-title">{{ $t('common.' + (typeTab === 1 ? 'sms' : 'email')) }}</div>

              <!-- 输入的手机/邮箱展示 -->
              <div class="input-info" v-if="typeTab === 1">
                {{ $t('register.code_subtitle_pro') }}
                <span class="black">{{ '+' + areaCode + ' ' + hideMobile + ' ' }}</span>
                {{ $t('register.code_subtitle_suffix') }}
              </div>
              <div class="input-info" v-if="typeTab === 2">
                {{ $t('register.code_subtitle_pro') }}
                <span class="black">{{ hideEmail }}</span>
                {{ $t('register.code_subtitle_suffix') }}
                {{ $t('common.email_tip') }}
              </div>

              <!-- 验证码表单 -->
              <input-sms class="vali-code-list" v-model="iptCodeSms"></input-sms>

              <div v-show="errorCode" class="reg-from-error vali-code">
                <v-errtip :error="errorCode"></v-errtip>
              </div>
              <div class="countdown-info" v-show="countdownSec < 120 && countdownSec > 0">
                {{ countdownSec + 's ' + $t('common.resend') }}
              </div>
              <div class="resend-code" v-show="countdownSec <= 0" @click="getVerifyMethod">
                {{ $t('common.resend') }}
              </div>

              <!-- 提交 -->
              <el-button
                class="reg-btn"
                type="yellow"
                @click="completeBefore"
                :loading="btnLoading"
                :disabled="btnDisabled"
              >
                {{ $t('register.btn_complete') }}
              </el-button>
            </div>
          </div>
        </div>
        <!-- 注册完毕 -->
        <!-- <div class="register-step__3" style="max-width:580px;" v-if="regStep === 3">
          <div class="img-top-wrap">
            <img v-if="newTheme == 'black'" src="~/assets/img/expressly/rejister_success_black.png" alt />
            <img v-if="newTheme == 'white'" src="~/assets/img/expressly/rejister_success_white.png" alt />
          </div>
          <div class="register_success_wrap">{{ $t('expressly.register_success') }}</div>
          <div class="register_success_tip">{{ $t('register.register_success_tip') }}</div>
          <div class="bottom-link-wrap">
            {{ homeCountdownSec + $t('register.count_time') + $t('common.jump') }}，
            <a href="javascript:void(0)" @click="goSwitch">{{ $t('register.linknow') }}</a>
          </div>
        </div> -->
      </div>
    </div>

    <!-- 验证弹窗 -->
    <protect-box
      :show.sync="protectShow"
      :net-status="netStatus"
      :re-status="reStatus"
      :site-type="siteType"
      :show-type="protectType"
      @recallback="reCallBack"
    ></protect-box>

    <!-- 离开弹层 -->
    <el-dialog
      title=""
      :visible.sync="centerDialogVisible"
      v-if="centerDialogVisible"
      width="370px"
      :show-close="false"
      class="register-dialog"
      center
    >
      <img class="exclamationImg" :src="exclamationImg" alt="" />
      <span class="register-dialog-text" v-html="registerExitText"></span>
      <span slot="footer" class="dialog-footer">
        <el-button type="cancel-V2" @click="handleJumpToPage">{{ $t('register.after') }}</el-button>
        <el-button type="primary" @click="centerDialogVisible = false">{{ $t('register.continue') }}</el-button>
      </span>
    </el-dialog>

    <!-- 注册成功跳转弹层 -->
    <!-- <el-dialog
      :title="$t('register.register_successful')"
      :visible.sync="resgisterSuccessDialog"
      v-if="resgisterSuccessDialog"
      width="400px"
      :show-close="true"
      class="register-success-dialog"
      @close="goHomePage"
      center
    >
      <span class="register-claim-text" v-html="registerClaimText"></span>
      <img class="claimImg" :src="claimImg" alt="" />
      <div slot="footer" class="dialog-footer">
        <el-button type="cancel-V2" @click="goHomePage">{{ $t('register.later') }}</el-button>
        <el-button type="primary" @click="getClaimNow">{{ $t('register.claim_now') }}</el-button>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import { awardsNum, getRegisterRemind } from '~/server/account/index';
import { mapState, mapGetters, mapMutations } from 'vuex';
import { clearLauncher, hideGlobalLoading, productCodeToSymbol, throttle, getAliyunCommonCaptcha } from '~/utils/utils';
import common from '~/common/common.js';
import CheckStyleNew from '~/components/common/CheckStyleNew';
import {
  areaCodeList,
  userSecurityPublicCheckLoginName,
  cloudValidateSwitch,
  overseasSmsStatus,
  validateConfigV2,
  getLongUrl
} from '~/server/common/index.js';
import { registerForMobileV2, registerForEmailV2 } from '~/server/user/index2.js';
import { getFinger } from '~/utils/deviceInfo.js';
import security from '~/components/follower-register/VerificationCode';
import protectBox from '~/components/common/protectDialog';
import { VERIFY_CODE_KEY } from '~/config/enum.conf.js';
import { verifyCodeSendPhone, verifyCodeSendEmail } from '~/server/common/';
import RegisterInput from '~/components/follower-register/RegisterInput';
// import SelAreaCode from '~/components/areaCode/selAreaCode.vue';
import AreaPhone from '~/components/AreaPhone';
import { reg_ga } from '~/utils/gaHelp';
// VipCode以及getChannelCodeFromVipCode驗證接口(API)
import { getChannelCodeFromVipCode, getRegistrationSuccessDialog } from '~/server/common/index.js'; //測試用，待修改路徑
import LocalCache, { CacheKey } from '~/utils/LocalCache';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { firebaseApp } from '~/plugins/firebase.js';
import axios from 'axios';
import { getBrowserType } from '~/utils/globalUtils.js';
import LeftCarouselImg from '~/components/LeftCarouselImg.vue';
import { Base64 } from 'js-base64';
import { broadcastChannel } from '~/utils/broadcastChannel';
import { getLangUrl, isBrowserH5 } from '../../../utils/utils';
import { CodeResult, RegisterResult, SubRegister } from '../../../utils/sensorsEvent';
import TokenCookie, { TokenKey } from '@/utils/TokenCookie';
import i18nconf from '@/config/request.i18n.conf';
import LocalCookie, { CookieKey } from '~/utils/localCookie';
import { getBtokLink } from '~/config/linkConfig/getBtokLink';
import LanguageList from '~/components/LanguageList.vue';
import { mixLink, otcLinkReturn } from 'utils'
import { isRtlLanguage,getLanguageSetAreaCode } from "~/utils/locale";
import { cryptoObj } from '~/utils/crypto.js';

export default {
  name: 'index',
  layout: 'blank',
  nuxt: {
    // 设置不缓存该组件
    noCache: true
  },
  components: {
    'check-style-new': CheckStyleNew,
    'input-sms': security,
    protectBox,
    RegisterInput,
    AreaPhone,
    SelAreaCode: () => import('~/components/areaCode/selAreaCode'),
    LeftCarouselImg,
    LanguageList
  },
  data() {
    return {
      showEmailTootip: false,
      registerExitText: '',
      awardsNumStr: 0,
      iptPhone: '',
      errorPhone: '',
      iptEmail: '',
      errorEmail: '',
      iptPwd: '',
      errorPwd: '',
      iptPwdConfirm: '',
      errorPwdConfirm: '',
      checked: true,
      errorProtocol: '',
      cardTimer: null,
      btnDisabled: false,
      btnLoading: false,
      showAreaCode: false,
      errorCode: '',
      countdownSec: 120,
      areaCode: '86',
      areaCodeList: [],
      regStep: 1, // 注册步骤控制
      typeTab: 2, // 注册选项 2:郵箱，1:手機
      countdownTimer: null, // 验证码倒计时
      resendLoading: false, // 验证码倒计时Loading，避免快速点击多次重发验证码
      protectShow: false,
      capInstance: '',
      nvcFlag: '',
      allFlag: false,
      recommendCodeStr: '',
      channelCodeStr: '',
      vipCodeStr: '',
      vipCodeShow: false,
      errorVip: '',
      iptCodeSms: '',
      validateFlag: false, //关闭云验证
      netStatus: false,
      reStatus: false,
      siteType: 'register', //注册
      protectType: 1, // 验证类型
      smsVerifyKey: '',
      emailVerifyKey: '',
      codeRuleShow: false,
      actCode: '',
      pwdEye: false,
      pwdEye2: false,
      langShow: false,
      langStr: '',
      textClearIcon1: false,
      textClearIcon: false,
      phoneClearIcon: false,
      emailClearIcon: false,
      vipClearIcon: false,
      isShowSelAreaDialog: false,
      ifSelArea: false,
      geetestValid: {},
      // 阿里云验证相关
      aliyunValid: {},
      overseasSmsStatusData: true, //海外短信渠道是否开启，true - 开启，false - 未开启
      overseasSmsMsg: '',
      focusPhoneInput: false,
      analytics: {},
      browserType: {},
      fromSupport: false, //判斷埋點開關
      clientIP: '', //註冊ip埋點
      fromSupportUrl: '',
      channelName: 'geetest',
      isFixed: false,
      fixedPosition: 0, // 固定位置
      centerDialogVisible: false,
      exclamationImg: require('@/assets/img/common/gift.svg'),
      isJumpToPage: true, // 是否弹了
      jumpToPageValue: '', //跳转路径
      geetestDestroyTimer: null, // 极验销毁定时器
      btokLink: '',
      aliyunCaptcha: null,
      loginLink: '', // 获取登录链接
      postfixList: ['@yahoo.com', '@skype.com', '@gmail.com', '@hotmail.com', '@syvip.com'], // 邮箱后缀
      pwdRule: [false, false, false, false], // 符合密碼規則開關
      pwdRuleShow: false, // 是否展示密碼規則提示
      focusPwdInput: false, // 密碼框是否在focus狀態
      resgisterSuccessDialog: false, // 是否展示注册成功跳转弹层
      registerClaimText: '',
      claimImg: require('@/assets/img/welfare/claimFornewUser.png'),
      claimLink: '', // 領取新手活動路徑
      homeTimer: null, // 跳轉首頁定时器
      authResult: null, // 接口返回错误提交
      blurTimer: null
    };
  },
  computed: {
    ...mapState(['locale', 'theme', 'newTheme', 'inviteCodeInfo']),
    ...mapGetters(['getLang']),
    recommendIco() {
      return require(`@/assets/img/new_index/recommend-ico-${this.locale}.svg`);
    },
    captchaLang() {
      return this.getLang || 'zh-CN';
    },
    languageType() {
      return i18nconf(this.getLang).lang;
    },
    pwdTypeStr() {
      if (this.pwdEye) {
        return 'text';
      } else {
        return 'password';
      }
    },

    pwdConfirm() {
      if (this.pwdEye2) {
        return 'text';
      } else {
        return 'password';
      }
    },

    iconFlag() {
      if (this.$t('links.lang') == '简体中文') {
        return 'cn';
      }
      if (this.$t('links.lang') == 'English') {
        return 'en';
      }
      if (this.$t('links.lang') == '한국어') {
        return 'ko';
      }
      if (this.$t('links.lang') == '日本語') {
        return 'ja';
      }
      if (this.$t('links.lang') == 'Tiếng Việt') {
        return 'vi';
      }
      if (this.$t('links.lang') == '繁體中文') {
        return 'tw';
      }
      if (this.$t('links.lang') == 'Русский') {
        return 'ru';
      }
      if (this.$t('links.lang') == 'Español') {
        return 'es';
      }
    },
    isRtl() {
      return isRtlLanguage(this.$route.path)
    },
    hideEmail() {
      return (
        this.iptEmail.substr(0, 1) + '****' + this.iptEmail.substr(this.iptEmail.indexOf('@'), this.iptEmail.length)
      );
    },
    hideMobile() {
      return '****' + this.iptPhone.substr(this.iptPhone.length - 4, this.iptPhone.length);
    },
    logoSrc() {
      return require(`@/assets/img/public/logoweex_black.svg`);
    }
  },
  mounted() {
    this.setCodes();
    this.registerExitText = this.$t('register.exitDefaultLoss');
    this.registerClaimText = this.$t('register.registerClaim');
    getRegisterRemind().then(res => {
      if (res.data.content) {
        this.registerExitText = res.data.content;
      }
    });
    getBtokLink().then(res => {
      this.btokLink = res;
    });
    const mainDom = document.getElementById('mainConWrap');
    if (mainDom) {
      mainDom.style.minWidth = '295px';
    }

    // 动态设置高度
    if (!isBrowserH5()) {
      let registerWarp = document.getElementsByClassName('register-flexible-wrapper');
      if (registerWarp.length > 0) {
        registerWarp[0].style.minHeight = document.documentElement.clientHeight + 50 + 'px';
      }
    }

    clearLauncher(); // 去掉联系我们
    hideGlobalLoading();
    this.initTheme();
    let tmpLangType = this.$route.query.languageType;
    if (tmpLangType) {
      let tmp = 'zh-CN';
      if (tmpLangType == 1) {
        tmp = 'zh-CN';
      }
      if (tmpLangType == 0) {
        tmp = 'en';
      }
      if (tmpLangType == 2) {
        tmp = 'ja';
      }
      if (tmpLangType == 3) {
        tmp = 'ko';
      }
      if (tmpLangType == 4) {
        tmp = 'vi';
      }
      if (tmpLangType == 5) {
        tmp = 'zh-TW';
      }
      if (tmpLangType == 6) {
        tmp = 'ru';
      }
      if (tmpLangType == 7) {
        tmp = 'es';
      }

      this.langStr = tmpLangType;
      this.$router.push(getLangUrl(tmp, this.$route.fullPath));
      // this.$router.push(this.$route.fullPath.replace(/^\/[^\/]+/, `/${tmp}`));
    }
    const { fromSupport: storeFromSupport } = this.inviteCodeInfo;
    const fromSupport = new URLSearchParams(window.location.search).get('fromSupport') || storeFromSupport;
    this.fromSupport = null !== fromSupport;
    this.fromSupportUrl = fromSupport && Base64.decode(fromSupport);
    this.analytics = getAnalytics(firebaseApp);
    this.browserType = getBrowserType();
    this.getClientIp();

    // cloudValidateSwitch().then(res => {
    //   if (+res.code === 0) {
    //     this.validateFlag = res.data.enabled;
    //     console.log("cloudValidateSwitch validateFlag:",res.data)
    //   }
    // });

    this.getAreaCodeList();
    //this.captcha(); //不使用

    // this.getAwardsNum();

    //在首页跳过来的快速注册
    let rname = this.$route.query.rname || '';
    console.log(rname);
    console.log(this);
    if (rname) {
      // if (this.isCN){ 暫不判斷語系
      if (true) {
        if (/^\d+$/g.test(rname)) {
          this.iptPhone = rname;
          this.typeTab = 1;
        } else {
          this.iptEmail = rname;
          this.typeTab = 2;
        }
      } else {
        this.email = rname;
      }
    }

    //查询境外短信接口状态
    this.overseasSmsStatus();

    // 使用 throttle 包装 handleScroll 方法
    this.debouncedHandleScroll = throttle(this.handleScroll, 30);
    // 监听滚动事件
    window.addEventListener('scroll', this.debouncedHandleScroll);
    // 监听页面回退
    window.addEventListener('popstate', this.handlePageRefresh);

    // 延迟执行
    setTimeout(_ => {
      // 为防止出现未知异常，此处再次给邀请码赋值
      const vCode = this.$route.query.vipCode;
      if (vCode && !this.vipCodeStr) this.vipCodeStr = vCode;

      this.getLoginHref();
      // 阿里云验证初始化
      this.getAliyunCaptcha();
    })
  },
  beforeRouteLeave(to, from, next) {
    
    // 挽留弹窗
    const registerReg = /\/register/;
    console.log('to.fullPath', registerReg.test(to.fullPath))
    if (!registerReg.test(to.fullPath)) {
      this.jumpToPageValue = to.fullPath;
      this.centerDialogVisible = true;
      window.history.pushState(null, null, from.fullPath);
      this.isJumpToPage = false;
    } else {
      next();
    }
  },
  methods: {
    ...mapMutations(['SET_THEME', 'SET_THEME_NEW']),
    getAliyunCaptcha() {
      const language = { 'zh-CN': 'cn', 'zh-TW': 'tw', en: 'en', vi: 'vi', ko: 'ko' }[this.getLang] || 'en';
      getAliyunCommonCaptcha({
        element: '#captcha-element', //页面上预留的渲染验证码的元素。
        button: '#registerEle', //触发验证码弹窗的元素
        captchaVerifyCallback: this.captchaVerifyCallback, // 业务请求(带验证码校验)回调函数
        onBizResultCallback: this.onBizResultCallback, // 业务请求结果回调函数
        getInstance: this.getInstance, // 绑定验证码实例函数
        language
      });
    },
    initTheme() {
      this.changeTheme('black');
    },
    // 跳转
    handleJumpToPage() {
      window.location.replace(window.location.origin + this.jumpToPageValue);
    },
     handleScroll() {
      const screenWidth = document.documentElement.clientWidth;
      // 宽度小于992可以不滚动 小屏幕和h5适配
      if (screenWidth < 992) return;
      // 获取滚动的垂直位置
      const scrollPosition = document.documentElement.scrollTop;
      this.isFixed = scrollPosition >= 80;
      // 如果固定元素已经显示，则计算它的 right 值
      if (scrollPosition >= 80) {
          const parentWidth = 1000;
          // 波斯语改变方向
          if(this.isRtl){
            this.fixedPosition = {left: (screenWidth - parentWidth) / 2 + 'px', right: 'unset'};
          }
          else{
            this.fixedPosition = {right: (screenWidth - parentWidth) / 2 + 'px', left: 'unset'};
          }
        }
    },
    // 查询境外短信接口状态
    overseasSmsStatus() {
      overseasSmsStatus().then(res => {
        if (res.code == '200') {
          this.overseasSmsStatusData = res.data;
          if (res.data == false) {
            this.overseasSmsMsg = this.$t('overseas-sms-status.register');
          }
        }
      });
    },
    showSelAreaDialog() {
      if (this.overseasSmsStatusData) {
        this.isShowSelAreaDialog = true;
        this.ifSelArea = true;
      }
    },
    //活動網址
    gotoEvent() {
      let href =
        'https://weexsupport.zendesk.com/hc/zh-cn/articles/7779233727897-OTC%E5%85%85%E5%80%BC%E5%85%8D%E6%89%8B%E7%BB%AD%E8%B4%B9';
      window.open(href);
    },
    // 顯示清除icon
    openClearIcon() {
      this.textClearIcon = true;
    },
    openClearIcon1() {
      this.textClearIcon1 = true;
    },
    openPhoneClearIcon() {
      this.phoneClearIcon = true;
    },
    openEmailClearIcon() {
      this.emailClearIcon = true;
    },
    openVipClearIcon() {
      this.vipClearIcon = true;
    },
    //  清除內容
    clearText() {
      this.iptPwdConfirm = '';
      this.textClearIcon = false;
      this.errorPwdConfirm = '';
    },
    clearPwd() {
      console.log('clearPwd');
      this.iptPwd = '';
      this.textClearIcon1 = false;
      this.errorPwd = '';
      // 清除密碼規則驗證
      this.pwdRule = [false, false, false, false];
    },
    clearPhone() {
      this.iptPhone = '';
      this.errorPhone = '';
    },
    clearEmail() {
      this.iptEmail = '';
      this.errorEmail = '';
    },
    clearVip() {
      this.vipCodeStr = '';
      this.vipClearIcon = false;
      this.errorVip = '';
    },
    hideAreaList() {
      this.showAreaCode = false;
    },
    setCodes() {
      this.actCode = this.$route.query.actCode;
      const { vipCode = '', channelCode = '' } = this.inviteCodeInfo;
      this.channelCodeStr = this.$route.query.channelCode || channelCode;
      this.vipCodeStr = this.$route.query.vipCode || vipCode;
      // if(!!this.vipCodeStr) this.vipCodeShow = true;
    },
    getAwardsNum() {
      awardsNum({ activityId: this.actCode }).then(res => {
        if (res.code == '00000') {
          this.awardsNumStr = res.data.total || 0;
        }
      });
    },
    goSwitch() {
      const { goBack, from } = this.$route.query;
      if (from) {
        // 路徑有帶from, 註冊完成直接跳回from路徑的活動
        if (from.startsWith('https://')) {
          window.location.href = from;
        } else {
          this.$router.push(this.$i18n.path(from));
        }
      } else if (goBack && goBack == 1) {
        // goBack判断是否返回上一级页面
        this.$router.go(-1);
      } else {
        // TODO 如果是H5类型，暂时不支持，需要跳到下载页面
        /*if (isBrowserH5()) {
          this.$router.push(this.$i18n.path('support/jumpdl'));
          return
        }*/
        // 打開註冊承完彈窗
        this.openRegistrationSuccessDialog();
      }
    },
    captcha() {
      var that = this;
      initNECaptchaWithFallback(
        {
          // config对象，参数配置
          captchaId: 'id',
          element: '#captcha',
          mode: 'popup',
          width: '400px',
          lang: this.captchaLang,
          enableClose: true,
          appendTo: '#netwrap',
          onVerify(err, data) {
            if (err) {
              return;
            }
            that.protectShow = false;
            // that.capInstance && that.capInstance.close();
            // that.beforeSendCode(data.validate, 1);
            if (that.capInstance) {
              that.capInstance.close();
              that.beforeSendCode(data.validate, 1);
            }
          }
        },
        function onload(instance) {
          // 初始化成功后得到验证实例instance，可以调用实例的方法
          that.capInstance = instance;
        },
        function onerror(err) {
          // 初始化失败后触发该函数，err对象描述当前错误信息
          console.log(err);
          // TODO 此处先屏蔽，待业务清晰后，再另做处理
          // that.errorProtocol = that.$t("common.nvc_err");
        }
      );
    },
    // 全局回车事件，隐藏问题：只有先把输入框 focus 时，回车才好使
    enterHandler() {
      if (this.regStep === 1) {
        this.goSecondStep();
      } else if (this.regStep === 2) {
        this.completeBefore();
      }
    },
    // 选择区号
    selectAreaCode(val) {
      if (val.areaCode == this.areaCode) {
        this.showAreaCode = false;
        return;
      }
      this.showAreaCode = false;
      this.areaCode = val.areaCode;
      this.errorPhone = '';
    },
    handlePhoneFocus() {
      this.focusPhoneInput = true;
    },
    handlePwdFocus() {
      this.pwdRuleShow = true;
    },
    // 一堆 blur 校验方法
    blurPhone() {
      this.focusPhoneInput = false;
      if (!this.iptPhone) {
        this.errorPhone = this.$t('register.err_phone_empty');
        this.allFlag = false;
        return;
      }

      // if (!common.telReg.test(this.iptPhone) && this.areaCode == "86") {
      //   this.errorPhone = this.$t("register.err_name");
      //   this.allFlag = false;
      //this.openPhoneClearIcon();
      //   return;
      // }
      this.errorPhone = '';
      this.allFlag = true;
    },
    focusEmail() {
      this.errorEmail = '';
      this.showEmailTootip = true;
    },
    blurEmail() {
      const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      this.blurTimer = setTimeout(() => {
        this.blurTimer && clearTimeout(this.blurTimer);
        this.showEmailTootip = false;
        if (!this.iptEmail) {
          this.errorEmail = this.$t('register.err_email_empty');
          this.allFlag = false;
          this.emailClearIcon = false;
          return;
        } else if (!emailRegex.test(this.iptEmail)) {
          this.errorEmail = this.$t('register.check_email');
          this.allFlag = false;
          this.emailClearIcon = false;
          return;
        }

        if (/[A-Z]+/.test(this.iptEmail)) {
          // @note 如果有大写字母则提示区分大小写
          this.errorEmail = this.$t('login.email_register_letter');
        }
      }, 300);

      // if (!common.emailReg.test(this.iptEmail)) {
      //   this.errorEmail = this.$t("register.err_name");
      //   this.allFlag = false;
      //   this.openEmailClearIcon();
      //   console.log("errorEmail:", this.iptEmail);
      //   return;
      // }
      this.allFlag = true;
    },
    blurPwd() {
      let pwdErr = false;
      //this.focusPwdInput = false;
      this.pwdRuleShow = false;
      this.pwdRule.forEach(rule => {
        if (!rule) {
          pwdErr = true;
        }
      });
      if (!this.iptPwd) {
        this.errorPwd = this.$t('register.err_pwd_empty');
        return;
      }
      if (pwdErr) {
        this.errorPwd = this.$t('register.err_pwd');
        this.allFlag = false;
        return;
      }
      this.errorPwd = '';
      this.allFlag = true;
    },
    blurPwdConfirm() {
      if (!this.iptPwdConfirm) {
        this.errorPwdConfirm = this.$t('common.pwd_confirm');
        this.allFlag = false;
        this.textClearIcon = false;
        return false;
      }
      if (this.iptPwdConfirm !== this.iptPwd) {
        this.errorPwdConfirm = this.$t('common.pwd_err_notcommon');
        this.allFlag = false;
        this.openClearIcon();
        return false;
      }

      this.errorPwdConfirm = '';
      this.allFlag = true;
    },
    blurVip() {
      if (this.vipCodeStr !== '') {
        this.openVipClearIcon();
        return false;
      }
    },

    // 验证手机号
    validatePhone() {
      let isPass = true;

      if (!this.iptPhone) {
        this.errorPhone = this.$t('register.err_phone_empty');
        isPass = false;
      }

      // 电话号码正则验证（已注释，可以根据需要启用）
      // if (!common.telReg.test(this.iptPhone) && this.areaCode === "86") {
      //   this.errorPhone = this.$t("register.err_name");
      //   return false;
      // }

      return isPass;
    },

  // 验证邮箱
  validateEmail() {
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    let isPass = true;

    if (!this.iptEmail) {
      this.errorEmail = this.$t('register.err_email_empty');
      isPass = false;
    } else if (!emailRegex.test(this.iptEmail)) {
      this.errorEmail = this.$t('register.err_email_invalid');
      isPass = false;
    }

    return isPass;
  },

  // 验证密码
  validatePassword() {
    let isPass = true;

    if (!this.iptPwd) {
      this.errorPwd = this.$t('register.err_pwd_empty');
      isPass = false;
    }

    // 密码不符合规则时返回 false
    if (this.errorPwd !== '') {
      return false;
    }

    return isPass;
  },

  // 验证协议勾选
  validateProtocol() {
    if (!this.checked) {
      this.errorProtocol = this.$t('register.err_protocol');
      return false;
    }
    return true;
  },

  valid() {
    // 判断当前选项卡类型
    let isPass = true;
    if (this.typeTab === 1) {
      isPass = this.validatePhone();
    } else {
      isPass = this.validateEmail();
    }

    // 继续密码验证
    if (!this.validatePassword()) {
      isPass = false;
    }

    // 继续协议验证
    if (!isPass || !this.validateProtocol()) {
      return false;
    }

    return true;
  },
    // 第一步提交
    async goSecondStep() {
      if (this.typeTab == 1) {
        SubRegister({ register: 'Mobile', invite_code: this.vipCodeStr || '无' });
      } else {
        SubRegister({ register: 'Email', invite_code: this.vipCodeStr || '无' });
      }
      this.btnDisabled = true;
      this.btnLoading = true;
      // 基本校验
      if (!this.valid()) {
        this.btnDisabled = false;
        this.btnLoading = false;
        return false;
      }
      // VipCode校验
      if (this.vipCodeStr && !(/^[0-9]*$/.test(this.vipCodeStr.substr(0, 1)) && this.vipCodeStr.length === 5)) {
        //let bodyFormData = new FormData();
        //bodyFormData.append('vipCode', this.vipCodeStr.trim());
        await getChannelCodeFromVipCode({
          //data: bodyFormData,
          //headers: { contentType: 'multipart/form-data' }
          vipCode: this.vipCodeStr.trim()
        })
          .then(res => {
            this.channelCodeStr = res.data.channelCode;
            this.vipCodeStr = res.data.agentInviteCode;
            this.btnLoading = false;
            this.btnDisabled = false;
          })
          .catch(e => {
            this.btnDisabled = false;
            this.btnLoading = false;
          });
        if (this.channelCodeStr == '-1') {
          //這裡待確認後端回傳什麼值('-1')
          BT.util.info(this.$t('register.err_vip_code'));
          return;
        }
      }
      if (this.validateFlag) {
        if (this.capInstance) {
          this.netStatus = true;
          this.protectShow = true;
          this.capInstance.popUp();
        }
        if (window.grecaptcha) {
          if (!this.capInstance) {
            this.protectType = 2;
            this.protectShow = true;
          }
          this.reStatus = true;
          return;
        }
        if (!this.capInstance && !window.grecaptcha) {
          this.beforeSendCode('', 1);
        }
      } else {
        this.beforeSendCode('', 1);
      }
    },
    // 验证验证码
    async completeBefore() {
      if (this.typeTab == 1) {
        if (!this.iptCodeSms) {
          this.errorCode = this.$t('verification_tips.fill_mobile');
          return;
        }
        if (this.iptCodeSms.length != 6) {
          this.errorCode = this.$t('verification_tips.err_mobile');
          return;
        }
      } else {
        if (!this.iptCodeSms) {
          this.errorCode = this.$t('verification_tips.fill_email');
          return;
        }
        if (this.iptCodeSms.length != 6) {
          this.errorCode = this.$t('verification_tips.err_email');
          return;
        }
      }
      let pwd = this.iptPwd;
      let rePwd = this.iptPwd;
      await cryptoObj(pwd, crypto => {
        pwd = crypto.cryptoStr;
      })
      await cryptoObj(rePwd, crypto => {
        rePwd = crypto.cryptoStr;
      })
      let param = {
        pwd,
        rePwd,
        areaCode: this.areaCode,
        languageType: this.languageType
        // registerChannel : this.registerChannel //等待API打通後後端回傳參數
      };
      if (this.actCode) {
        param.actCode = this.actCode;
      } else {
        if (this.getLang === 'en') {
          param.actCode = '5';
        }
        if (this.getLang === 'ko') {
          param.actCode = '6';
        }
        if (this.getLang === 'ja') {
          param.actCode = '7';
        }
        if (this.getLang === 'zh-TW') {
          param.actCode = '8';
        }
        if (this.getLang === 'ru') {
          param.actCode = '9';
        }
      }
      if (this.vipCodeStr) {
        param.registerVipNo = this.vipCodeStr;
      }
      if (this.channelCodeStr) {
        param.registerChannel = this.channelCodeStr;
      }

      param.verifyCode = this.iptCodeSms;
      if (this.typeTab == 1) {
        param.mobile = this.iptPhone;
        param.mobileCodeKey = this.smsVerifyKey;
      } else {
        param.email = this.iptEmail;
        param.emailCodeKey = this.emailVerifyKey;
      }
      this.btnDisabled = true;
      this.btnLoading = true;
      getFinger(finger => {
        param.terminalCode = finger;
        this.completeReg(param);
      });
    },

    // 完成注册
    completeReg(param) {
      //最后一步 ga统计数据
      reg_ga();
      if (this.actCode) {
        param.actCode = this.actCode;
      }
      this.btnDisabled = true;
      this.btnLoading = true;
      if (this.typeTab == 1) {
        registerForMobileV2(param)
          .then(res => {
            if (res.code == 0 && this.fromSupport) {
              RegisterResult({ register: 'Mobile', invite_code: this.vipCodeStr, is_success: true, fail_reason: '无' });
              logEvent(this.analytics, 'finish_register_event', {
                mobile: param.mobile,
                platform: this.browserType.system,
                vipCode: this.vipCodeStr,
                channelCode: this.channelCodeStr,
                visitUrl: this.fromSupportUrl
              });
            } else {
              RegisterResult({
                register: 'Mobile',
                invite_code: this.vipCodeStr,
                is_success: false,
                fail_reason: res.msg || '无'
              });
            }
            this.completeCallFn(res);
          })
          .catch(e => {
            this.btnDisabled = false;
            this.btnLoading = false;
          });
      } else {
        console.log('param 发送邮件->', param);
        registerForEmailV2(param)
          .then(res => {
            if (res.code == 0 && this.fromSupport) {
              RegisterResult({ register: 'Email', invite_code: this.vipCodeStr, is_success: true, fail_reason: '无' });
              logEvent(this.analytics, 'finish_register_event', {
                email: param.email,
                platform: this.browserType.system,
                vipCode: this.vipCodeStr,
                channelCode: this.channelCodeStr,
                visitUrl: this.fromSupportUrl
              });
            } else {
              RegisterResult({
                register: 'Email',
                invite_code: this.vipCodeStr,
                is_success: false,
                fail_reason: res.msg || '无'
              });
            }
            this.completeCallFn(res);
          })
          .catch(e => {
            this.btnDisabled = false;
            this.btnLoading = false;
          });
      }
    },
    completeCallFn(res) {
      // 清空 让挽留弹窗离开
      if (+res.code == 0) {
        // 只有请求成功且后端响应success时候清空，避免后端响应失败会清空
        this.iptEmail = '';
        this.iptPhone = '';
        // 注册成功后，matomo埋入信息，storage记录userId
        if (res.data && res.data.userInfo && res.data.userInfo.userId) {
          const data = res.data;
          const uid = data.userInfo.userId;
          if (data.rtoken) {
            TokenCookie.set(TokenKey.R_TOKEN, data.rtoken);
            broadcastChannel('setItem', 'bt_uid', uid);
            TokenCookie.set(TokenKey.CC_TOKEN, data.accessToken);
            broadcastChannel('setItem', 'lastLoginTime', data.lastLogin.date);

            this.$endAuthToken(); // 清除token鉴权 - 放在极端情况：设置token的同时进行了鉴权，会导致登录失效
            // 加refreshToken，放入token过期时间
            if (data.accessTokenExpire) {
              TokenCookie.set(TokenKey.CC_TOKEN_EXPIRE, data.accessTokenExpire);
            }
            if (data.refreshToken) {
              TokenCookie.set(TokenKey.REFRESH_TOKEN, data.refreshToken);
            }
            if (data.refreshTokenExpire) {
              TokenCookie.set(TokenKey.REFRESH_TOKEN_EXPIRE, data.refreshTokenExpire);
            }
            this.$startAuthToken(); // 启动token鉴权

            this.$store.commit('SET_USER', data.userInfo);
          }
          //console.log("user_id:",uid)
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          //stg環境key:G-H684FDKJ2X
          gtag('config', 'G-H684FDKJ2X', {
            user_id: uid
          });
          //this.$matomo.trackEvent("会员操作", "注册", `UserID：${res.data.userInfo.userId}`, `会员注册。userId：${res.data.userInfo.userId}`);
        }
        this.registerFinally();
        //this.openRegistrationSuccessDialog();
      } else {
        this.btnDisabled = false;
        this.btnLoading = false;
        if (res.code == '20503' || res.code == '1004' || res.code == '429') {
          BT.util.error(res.msg);
          return;
        }
        if (res.msg) {
          this.errorCode = res.msg;
        }
      }
    },
    reCallBack(token) {
      this.beforeSendCode(token, 2);
    },
    beforeSendCode(validate, captchaType) {
      let _this = this;
      let param = {
        areaCode: _this.areaCode,
        captchaType: captchaType
      };
      if (validate) {
        param.captchaValidate = validate;
      }
      if (_this.typeTab === 1) {
        param.loginName = _this.iptPhone;
        param.type = 'mobile';
      } else {
        param.loginName = _this.iptEmail;
        param.type = 'email';
      }
      _this.btnDisabled = true;
      _this.btnLoading = true;
      if (this.fromSupport) {
        logEvent(this.analytics, 'register_event', {
          loginName: param.loginName,
          platform: this.browserType.system,
          visitUrl: this.fromSupportUrl,
          visitIp: this.clientIP,
          vipCode: this.vipCodeStr
        });
      }
      userSecurityPublicCheckLoginName(param)
        .then(res => {
          _this.btnDisabled = false;
          _this.btnLoading = false;
          _this.capInstance && _this.capInstance.refresh();
          if (+res.code === 0 || res.code === '00200' || res.code === '00100') {
            _this.nvcFlag = true;
            //this.sendValiCode();
            /* this.geetestCapcha(); */
            this.getVerifyMethod();
            return;
          }
          if (res.code === '20503') {
            // 该账号已经注册
            _this.nvcFlag = true;
            BT.util.error(res.msg); // 此处和原来不一样
          } else if (res.code == '20405' || res.code == '20903') {
            _this.nvcFlag = true;
            this.errorPhone = res.msg;
          } else if (res.code == '20428') {
            this.errorEmail = res.msg;
          } else {
            _this.nvcFlag = true;
            _this.errorProtocol = res.msg;
          }
        })
        .catch(() => {
          _this.btnDisabled = false;
          _this.btnLoading = false;
          _this.capInstance && _this.capInstance.refresh();
        });
    },
    // 发送验证码后
    sendAfter() {
      let _this = this;
      // 倒计时前先重置计时器
      if (this.countdownTimer) {
        clearInterval(this.countdownTimer);
        this.countdownTimer = null;
      }
      _this.countdownTimer = common.tickerCountDown(120, 1000, num => {
        _this.countdownSec = num;
        // 开始倒计时开始，重置resendLoading
        _this.resendLoading = false;
        if (_this.regStep == 1) {
          this.btnDisabled = false;
          this.btnLoading = false;
          _this.regStep = 2;
        }
      });
    },
    formatSecond(secs) {
      let hr = Math.floor(secs / 3600).toString();
      let min = Math.floor((secs - hr * 3600) / 60).toString();
      let sec = parseInt(secs - hr * 3600 - min * 60).toString();

      if (min.length < 2) {
        min = '0' + min;
      }
      if (sec.length < 2) {
        sec = '0' + sec;
      }
      if (hr.length < 2) {
        hr = '0' + hr;
      }
      if (hr) hr += ':';
      // console.log(hr + min + ':' + sec)
      return hr + min + ':' + sec;
    },
    sendValiCode() {
      return new Promise((resolve, reject) => {
        let paramMap = {};
        if (this.channelName === 'aliyun') {
          // 阿里云验证传参
          paramMap = {
            sceneId: this.aliyunValid.sceneId,
            captchaVerifyParam: JSON.stringify(this.aliyunValid)
          };
        } else {
          paramMap = {
            lot_number: this.geetestValid.lot_number,
            captcha_output: this.geetestValid.captcha_output,
            pass_token: this.geetestValid.pass_token,
            gen_time: this.geetestValid.gen_time
          };
        }
        if (this.typeTab === 1) {
          let param = {
            areaCode: this.areaCode,
            mobile: this.iptPhone,
            action: 3012,
            address: this.iptPhone,
            bizType: VERIFY_CODE_KEY.SMS.REGISTER,
            sendType: 'SMS',
            languageType: this.languageType,
            channelName: this.channelName,
            paramMap
          };
          if (this.authResult) {
            param.authResult = this.authResult;
          }
          if (this.smsVerifyKey) {
            param.retry = 1;
            param.verifyKey = this.smsVerifyKey;
          }

          verifyCodeSendPhone(param).then(res => {
            this.aliyunCaptcha && this.aliyunCaptcha.refresh();
            this.aliyunCaptcha && this.aliyunCaptcha.hide();
            if (res.code == '20801') {
              resolve(false);
            } else {
              resolve(true);
            }
            if (res.code === '00000') {
              if (res.data.code == '90016') {
                let timeStr = this.formatSecond(res.data.msg);
                CodeResult({
                  Business_scene: 'Register',
                  code_type: 'SMS',
                  is_success: false,
                  fail_reason: '验证码申请次数频繁，请' + timeStr + '后再申请'
                });
                this.errorPhone = '验证码申请次数频繁，请' + timeStr + '后再申请';
                this.btnDisabled = false;
                this.btnLoading = false;
                this.resendLoading = false;
              } else {
                CodeResult({ Business_scene: 'Register', code_type: 'SMS', is_success: true });
                this.smsVerifyKey = res.data.verifyKey;
                this.sendAfter();
              }
            } else if (res.code === '90030') {
              this.resendLoading = false;
              CodeResult({
                Business_scene: 'Register',
                code_type: 'SMS',
                is_success: false,
                fail_reason: this.$t('new_account.not_resend')
              });
              this.errorCode = this.$t('new_account.not_resend');
            } else if (
              res.code === '90010' ||
              res.code === '90011' ||
              res.code === '90021' ||
              res.code === '90022' ||
              res.code === '90023' ||
              res.code === '90024' ||
              res.code === '90025' ||
              res.code === '90026'
            ) {
              this.resendLoading = false;
              CodeResult({
                Business_scene: 'Register',
                code_type: 'SMS',
                is_success: false,
                fail_reason: this.$t('new_account.err_to_refresh')
              });
              this.errorCode = this.$t('new_account.err_to_refresh');
            } else if (res.code === '90016') {
              this.resendLoading = false;
              CodeResult({
                Business_scene: 'Register',
                code_type: 'SMS',
                is_success: false,
                fail_reason: this.$t('common.wait_few_min')
              });
              this.errorCode = this.$t('common.wait_few_min');
            } else if (res.code === '90047') {
              CodeResult({ Business_scene: 'Register', code_type: 'SMS', is_success: false, fail_reason: res.msg });
              this.errorPhone = res.msg;
              this.btnDisabled = false;
              this.btnLoading = false;
              this.resendLoading = false;
            } else {
              this.resendLoading = false;
              CodeResult({
                Business_scene: 'Register',
                code_type: 'SMS',
                is_success: false,
                fail_reason: res.msg || ''
              });
              if (res.msg) {
                BT.util.error(res.msg);
              }
            }
          });
        } else {
          let param = {
            email: this.iptEmail,
            action: 3011,
            address: this.iptEmail,
            bizType: VERIFY_CODE_KEY.EMAIL.REGISTER,
            sendType: 'EMAIL',
            languageType: this.languageType,
            channelName: this.channelName,
            paramMap
          };
          if (this.authResult) {
            param.authResult = this.authResult;
          }
          if (this.emailVerifyKey) {
            param.retry = 1;
            param.verifyKey = this.emailVerifyKey;
          }

          verifyCodeSendEmail(param).then(res => {
            this.aliyunCaptcha && this.aliyunCaptcha.refresh();
            this.aliyunCaptcha && this.aliyunCaptcha.hide();
            if (res.code == '20801') {
              resolve(false);
            } else {
              resolve(true);
            }
            if (res.code === '00000') {
              CodeResult({ Business_scene: 'Register', code_type: 'Email', is_success: true });
              this.emailVerifyKey = res.data.verifyKey;
              this.sendAfter();
            } else if (res.code === '90030') {
              this.resendLoading = false;
              CodeResult({
                Business_scene: 'Register',
                code_type: 'Email',
                is_success: false,
                fail_reason: this.$t('new_account.not_resend')
              });
              this.errorCode = this.$t('new_account.not_resend');
            } else if (
              res.code === '90010' ||
              res.code === '90011' ||
              res.code === '90021' ||
              res.code === '90022' ||
              res.code === '90023' ||
              res.code === '90024' ||
              res.code === '90025' ||
              res.code === '90026'
            ) {
              this.resendLoading = false;
              CodeResult({
                Business_scene: 'Register',
                code_type: 'Email',
                is_success: false,
                fail_reason: this.$t('new_account.err_to_refresh')
              });
              this.errorCode = this.$t('new_account.err_to_refresh');
            } else if (res.code === '90016') {
              this.resendLoading = false;
              CodeResult({
                Business_scene: 'Register',
                code_type: 'Email',
                is_success: false,
                fail_reason: this.$t('common.wait_few_min')
              });
              this.errorCode = this.$t('common.wait_few_min');
            } else {
              this.resendLoading = false;
              CodeResult({
                Business_scene: 'Register',
                code_type: 'Email',
                is_success: false,
                fail_reason: res.msg || ''
              });
              if (res.msg) {
                BT.util.error(res.msg);
              }
            }
          });
        }
      });
    },
    setTypeTab(val) {
      if (this.typeTab == val) {
        return;
      }

      this.typeTab = val;
      this.iptPhone = '';
      this.iptEmail = '';
      this.iptPwd = '';
      this.iptPwdConfirm = '';
      this.errorPhone = '';
      this.errorEmail = '';
      this.errorPwd = '';
      this.errorPwdConfirm = '';
      this.errorCode = '';
      this.errorProtocol = '';
      this.showAreaCode = false;
      this.textClearIcon = false;
      this.textClearIcon1 = false;
    },
    // 区号列表
    getAreaCodeList() {
      areaCodeList({
        languageType: this.languageType
      }).then(res => {
        this.areaCodeList = res.data.areaCodeList || [];
        this.areaCode = getLanguageSetAreaCode(this.getLang); // 根据语言设置区号
      });
    },
    // 注册完成，倒计时去首页或是活動頁面
    registerFinally() {
      // TODO 如果是H5类型(不論是否來自活動頁面)，暂时不支持，需要跳到下载页面
      if (isBrowserH5()) {
        this.$router.push(this.$i18n.path('download'));
        return;
      }
      this.goSwitch();
    },
    // 注册完成，判斷是否彈出註冊完成談窗或是直接跳去首頁
    openRegistrationSuccessDialog() {
      const param = {
        vipCode: this.vipCodeStr,
        type: '2' //类型 1:web登录页， 2:web注册页， 3:h5注册页
      };
      getRegistrationSuccessDialog(param)
        .then(res => {
          if (res.code === '00000') {
            if (res.data.imgUrl !== null && res.data.imgUrl !== '') {
              this.claimLink = res.data.imgUrl;
              this.getClaimNow();
            } else {
              this.goHomePage();
            }
          } else {
            BT.util.error(res.msg);
            this.goHomePage();
          }
          this.btnDisabled = false;
          this.btnLoading = false;
        })
        .catch(e => {
          this.btnDisabled = false;
          this.btnLoading = false;
          this.goHomePage();
        });
    },
    getLoginHref() {
      let urlLink = 'login';
      let query = {};
      // TODO 如果是H5类型，暂时不支持，需要跳到下载页面
      if (isBrowserH5()) {
        urlLink = 'download';
      }
      if (location.href.indexOf('trade') > -1) {
        query.from = 'trade/' + (this.$route.params.coin || '');
      } else if (location.href.indexOf('futures/classic') > -1) {
        query.from = 'futures/classic/' + productCodeToSymbol(this.$route.params.contract || '');
      } else if (location.href.indexOf('futures/mock-trading') > -1) {
        query.from = 'futures/mock-trading/' + productCodeToSymbol(this.$route.params.contract || '', true);
      } else if (location.href.indexOf('challenging/v2') > -1) {
        query.from = 'challenging/v2';
      } else if (location.href.indexOf('challenging') > -1) {
        query.from = 'challenging';
      } else if (location.href.indexOf('copytradingmix') > -1) {
        query.from = 'copytradingmix';
      } else {
        if (this.$route.query.from) {
          query.from = this.$route.query.from;
        }
      }
      this.loginLink = this.getUrlLink(this.$i18n.path(urlLink), query);
    },
    getUrlLink(url, query) {
      if (!query) {
        return url;
      }
      const queryParams = new URLSearchParams(query).toString();
      return `${url}?${queryParams}`;
    },
    selArea(code) {
      this.isShowSelAreaDialog = false;
      if (code) this.areaCode = code;
    },
    // 判断是极验还是阿里云
    getVerifyMethod() {
      validateConfigV2()
        .then(resData => {
          this.authResult = null;
          if (+resData.code === 0) {
            this.channelName = resData.data.validate.channelName || 'geetest';
            if (resData.data.validate.channelName === 'aliyun') {
              //点击事件触发阿里云验证
              document.getElementById('registerEle').click();
            } else {
              this.geetestCapcha();
            }
          }
        })
        .catch(error => {
          this.authResult = {
            result: true,
            error
          };
          this.geetestCapcha();
        });
    },
    geetestCapcha() {
      // 如果重发验证码Loading中则返回；
      if (this.resendLoading) return;
      this.resendLoading = true;
      let geetestLang = 'zho';
      switch (this.getLang) {
        case 'zh-CN': {
          geetestLang = 'zho';
          break;
        }
        case 'zh-TW': {
          geetestLang = 'zho-tw';
          break;
        }
        case 'en': {
          geetestLang = 'eng';
          break;
        }
        default: {
          // 因极验只支持中文简/繁体和英文，所以中文环境下使用中文，其他都使用英文
          geetestLang = 'eng';
          break;
        }
      }

      let _this = this;
      let captchaConfig = {
        captchaId: process.env.CAPTCHA_ID, // captchaId由後台取得必须的配置参数
        product: 'bind',
        language: geetestLang,
        hideSuccess: true
      };
      initGeetest4(captchaConfig, function(captchaObj) {
        captchaObj
          .onReady(function() {
            //验证码ready之后才能调用showCaptcha方法显示验证码
            captchaObj.showCaptcha(); //显示验证码
          })
          .onSuccess(function() {
            //验证码正确的业务逻辑
            _this.geetestValid = captchaObj.getValidate();
            delete _this.geetestValid.captcha_id;
            _this.sendValiCode();
            captchaObj.reset();
            captchaObj.destroy();
            captchaObj = null;
          })
          .onFail(function(failObj) {
            //验证码错误信息做一些统计
            //alert("验证失败请稍后重试");
            _this.btnDisabled = false;
            _this.btnLoading = false;
            _this.resendLoading = false;
            this.geetestDestroyTimer = setTimeout(_ => {
              captchaObj.destroy();
              captchaObj = null;
            }, 2000);
          })
          .onClose(function() {
            // 关闭人机校验弹框触发
            _this.btnDisabled = false;
            _this.btnLoading = false;
            _this.resendLoading = false;
            this.geetestDestroyTimer = setTimeout(_ => {
              captchaObj.destroy();
              captchaObj = null;
            }, 2000);
          })
          .onError(function() {
            //your code
          });
      });
    },
    async captchaVerifyCallback(captchaVerifyParam) {
      // 阿里云验证成功后
      this.aliyunValid = JSON.parse(captchaVerifyParam);
      const captchaResult = await this.sendValiCode();
      return {
        captchaResult, // 验证码验证是否通过，boolean类型，必选
        bizResult: true
      };
    },
    onBizResultCallback(bizResult) {
      // 返回成功调用
      console.log(bizResult);
    },
    getInstance(instance) {
      this.aliyunCaptcha = instance;
    },
    /***
     * 更改主题
     */
    changeTheme(type) {
      this.SET_THEME_NEW(type);
      this.SET_THEME();
    },
    changeNewTheme() {
      let theme = this.newTheme === 'white' ? 'black' : 'white';
      this.changeTheme(theme);
    },
    async getClientIp() {
      if (!this.fromSupport) {
        return;
      }
      //debugger
      console.log('vipCodeStr:', this.vipCodeStr);
      this.clientIP = await axios.get('https://api.ipify.org/?format=json').then(res => res.data.ip);
      //this.clientIP = getInstance('').get('https://api.ipify.org/?format=json').then((res) => res.data.ip);
      logEvent(this.analytics, 'register_visit_event', {
        visit: '注册页访问次数',
        visitUrl: this.fromSupportUrl,
        visitIp: this.clientIP,
        vipCode: this.vipCodeStr
      });
    },
    // 確認郵箱後綴
    selectPostfix(postfix) {
      this.iptEmail =
        this.iptEmail.indexOf('@') !== -1
          ? `${this.iptEmail.substr(0, this.iptEmail.indexOf('@'))}${postfix}`
          : `${this.iptEmail}${postfix}`;
    },
    // 较验密码要求
    validatePwdRule(val) {
      // 8~32个字符匹配
      val.length >= 8 && val.length <= 32 ? (this.pwdRule[0] = true) : (this.pwdRule[0] = false);

      // 至少一个数字匹配
      const pattern = /\d/;
      pattern.test(val) ? (this.pwdRule[1] = true) : (this.pwdRule[1] = false);

      // 至少一个英文字母匹配
      const pattern1 = /[a-zA-Z]/;
      pattern1.test(val) ? (this.pwdRule[2] = true) : (this.pwdRule[2] = false);

      // 特殊字符匹配
      const pattern2 = /[~`!@#$%^&*()_\-+={}[\]|;:,<>.?\/]/;
      pattern2.test(val) ? (this.pwdRule[3] = true) : (this.pwdRule[3] = false);
    },
    // 跳轉到首頁
    goHomePage() {
      this.homeTimer = setTimeout(() => {
        this.$router.push(this.$i18n.path(this.$route.query.from || ''));
      }, 1000);
    },
    // 打開後台配置的新手活動頁面
    getClaimNow() {
      window.location.href = `${window.location.origin}/${this.locale}${this.claimLink}`;
    },
    // 驗證是否匹配並且展示郵箱後綴提示
    checkPostFixShow(mail, postfix) {
      if (mail.length === 0) {
        return false;
      }
      if (mail.indexOf(postfix) !== -1) {
        return false;
      }
      const emailPostFix =
        mail.indexOf('@') !== -1 ? `${mail.substr(0, mail.indexOf('@'))}${postfix}` : `${mail}${postfix}`;
      return emailPostFix.indexOf(mail) !== -1 && this.showEmailTootip;
    },
    getValidIptMail(mailStr) {
      const validMail = mailStr.indexOf('@') !== -1 ? mailStr.substr(0, mailStr.indexOf('@')) : mailStr;
      return validMail;
    }
  },
  watch: {
    locale: {
      immediate: true,
      handler(newValue, oldValue) {
        if (process.browser) {
          this.$nextTick(() => {
            this.getLoginHref();
          });
        }
      }
    },
    inviteCodeInfo(val) {
      if (val instanceof Object) {
        const { vipCode = '', channelCode = '', fromSupport: storeFromSupport } = val;
        this.channelCodeStr = this.$route.query.channelCode || channelCode;
        this.vipCodeStr = this.$route.query.vipCode || vipCode;
        // if(!!this.vipCodeStr) this.vipCodeShow = true;
        const fromSupport = new URLSearchParams(window.location.search).get('fromSupport') || storeFromSupport;
        this.fromSupport = null !== fromSupport;
        this.fromSupportUrl = fromSupport && Base64.decode(fromSupport);
        this.getClientIp();
      }
    },
    errorPhone(val) {
      if (val) this.overseasSmsMsg = '';
      if (!this.overseasSmsStatusData && val.trim() == '')
        this.overseasSmsMsg = this.$t('overseas-sms-status.register');
    },
    iptPhone(val) {
      if (val && this.areaCode === '86') {
        this.iptPhone = val.replace(/[\D]+/g, '').substring(0, 11);
      }
      if (val) {
        this.iptPhone = val.replace(/[\D]+/g, '');
        this.errorPhone = '';
        this.phoneClearIcon = true;
      } else {
        this.phoneClearIcon = false;
      }
    },
    iptEmail(val) {
      if (val) {
        this.errorEmail = '';
        this.iptEmail = val.toLowerCase();
        this.emailClearIcon = true;
      } else {
        this.emailClearIcon = false;
      }
    },
    iptPwd(val) {
      if (val) {
        this.iptPwd = val.replace(/[\u4E00-\u9FA5]/g, '');
        // if (val.length > 32) {
        //   this.iptPwd = val.substring(0, 32);
        // }
        this.validatePwdRule(val); // 较验密码要求
        this.errorPwd = '';
        //this.textClearIcon1 = true; // 清除密碼按鈕不展示
      } else {
        this.textClearIcon1 = false;
        this.pwdRule = [false, false, false, false];
      }
    },
    iptPwdConfirm(val) {
      if (val) {
        this.iptPwdConfirm = val.replace(/[\u4E00-\u9FA5]/g, '');
        // if (val.length > 32) {
        //   this.iptPwdConfirm = val.substring(0, 32);
        // }
        this.errorPwdConfirm = '';
        this.textClearIcon = true;
      } else {
        this.textClearIcon = false;
      }
    },
    iptCodeSms(val) {
      if (val && val.length < 6) {
        this.errorCode = '';
      }
      // 验证码足位,后续自动提交
      if (val.length === 6) {
        this.completeBefore();
      }
    }
  },
  beforeDestroy() {
    if (this.blurTimer) clearTimeout(this.blurTimer);
    if (this.cardTimer) clearTimeout(this.cardTimer);
    if (this.geetestDestroyTimer) clearTimeout(this.geetestDestroyTimer);
    if (this.homeTimer) clearTimeout(this.homeTimer);
    this.$children.forEach(child => {
      child.$destroy();
      child = null;
    });
    this.$children.length = 0;
    const mainDom = document.getElementById('mainConWrap');
    if (mainDom) {
      mainDom.style.minWidth = '1350px';
    }
    // 清除最后的定时器
    this.debouncedHandleScroll.cancel();
    // 移除滚动事件监听器
    window.removeEventListener('scroll', this.debouncedHandleScroll);
    window.removeEventListener('popstate', this.handlePageRefresh);
    // 删除阿里云相关元素，否则再次mount多次调用会导致多次回调
    document.getElementById('aliyunCaptcha-mask')?.remove();
    document.getElementById('aliyunCaptcha-window-popup')?.remove();
  }
};
</script>

<style lang="less">
@import '~@/less/login-register-input.less';
/*rtl:begin:ignore*/
#aliyunCaptcha-window-popup,.geetest_captcha{
  direction: ltr;
  unicode-bidi: embed;
}
html[dir="rtl"]{
  .tg-jiantou{
    // 旋转180度
    transform: rotate(180deg)!important;
  }

  .register-input__wrapper input{
    // direction: ltr!important;
    // text-align: right!important;
    background: hsla(0, 0%, 100%, .0);
  }

  .register_bg .register-flexible-wrapper .register-account-row .area-code .area-code__display{
    direction: ltr!important; 
    text-align: right!important;
  }

  .icon_down{
    margin-right: 0!important;
    margin-left: 10px!important;
  }

}
/*rtl:end:ignore*/


// html[dir="rtl"]{
//   .password-input-suffix {
//     left: 10px!important;
//     right: auto!important;
//   }
//   .readMeCheckImg{
//     margin-right: 0;
//     margin-left: 8px;
//   }
//   .register-input__wrapper .error-message{
//     right: 0;
//     left: auto;
//   }

//   .register_bg .register-flexible-wrapper .logo-title{
//     left: unset;
//     right: 40px;
//   }

//   .register_bg .register-flexible-wrapper .register-account-row .clear{
//     left: 17px;
//     right: auto;
//   }

//   .register-input__wrapper .register-input__inner input{
//     padding-right: 16px;
//   }

//   .register_bg .register-flexible-wrapper .right-part-main .right-part-tg .tg-jiantou{
//     // 旋转180度
//     transform: rotate(180deg);
//   }

//   .register_bg .register-flexible-wrapper .right-part-main .right-part-tg .tg-icon{
//     margin-right: unset;
//     margin-left: 16px;
//     // transform: rotate(180deg);
//   }

//   .register_bg .register-flexible-wrapper .register-account-row .area-code{
//     justify-content: flex-end;
//     border-top-left-radius: 0;
//     border-bottom-left-radius: 0;
//     border-top-right-radius: 16px;
//     border-bottom-right-radius: 16px;
//   }

//   .register_bg .register-flexible-wrapper .register-account-row .phoneInput .register-input__wrapper{
//     border-top-right-radius: 0;
//     border-bottom-right-radius: 0;
//     border-top-left-radius: 16px;
//     border-bottom-left-radius: 16px;
//   }

//   .register_bg .register-flexible-wrapper .register-account-row .area-code .code_line{
//     left: unset;
//     right: 89px;
//   }

//   .register-input__wrapper .register-input__label{
//     padding-right: 16px;
//   }

//   .register_bg.black .register-flexible-wrapper .register-account-row .area-code .area-code-list .sel-area__code .search input{
//     background-position: 3%;
//   }

//   @media screen and (min-width: 1200px) {
//     .register_bg .left-part-main {
//         margin-right: unset;
//         margin-left: 64px;
//     }
//   }
// }

.register_bg {
  background: @theme-white;
  .medium-font();
  color: #fff;
  min-height: 100vh;

  &.white {
    color: #000;
    .register-dialog-text {
      color: #000;
    }
    .register-dialog {
      .el-dialog {
        background: #fff;
        border-radius: 24px;
      }
      .el-dialog__body {
        padding: 0 25px 20px 25px;
      }
      .el-dialog__footer {
        padding-bottom: 30px;
        button {
          border-radius: 12px;
          padding: 14px 20px;
        }
        .el-button--default {
          background: #77798914;
          color: #000;
          border: none;
          width: 150px;
        }
        .el-button--primary {
          border-radius: 12px !important;
          padding: 12px 6px !important;
          width: 150px;
        }
      }
    }
  }
  &.black {
    background: @black-bg-primary-V2 !important;
    .register-dialog {
      .el-dialog {
        background: @black-bg-primary-V2;
        border-radius: 24px;
      }
      .el-dialog__body {
        padding: 0 24px 24px;
      }
      .el-dialog__footer {
        padding: 0px 24px 24px;
        .dialog-footer {
          display: flex;
          justify-content: space-between;
        }
        button {
          width: 150px;
          height: 48px;
          border-radius: 12px !important;
          padding: 14px 20px;
          border: 0px;
        }
        .el-button--primary {
          padding: 12px 6px !important;
        }
      }
    }
    .register-success-dialog {
      .el-dialog {
        background: @black-fill-popup1-V2;
        border-radius: 24px;
      }
      .el-dialog__header {
        padding: 24px;
        .el-dialog__headerbtn {
          width: 16px !important;
          height: 16px !important;
          top: 26px !important;
          right: 20px !important;
        }
        .el-dialog__title {
          color: @black-text-primary-V2;
          line-height: 21px;
        }
      }
      .el-dialog__body {
        padding: 0 24px 36px;
      }
      .el-dialog__footer {
        padding: 0px 24px 24px;
        .dialog-footer {
          display: flex;
          justify-content: space-between;
        }
        button {
          width: 160px;
          height: 48px;
          border-radius: 16px !important;
          padding: 14px 20px;
          .medium-font();
          border: 0px;
        }
        .el-button--primary {
          padding: 12px 6px !important;
        }
      }
    }
  }
  .exclamationImg {
    width: 80px;
    height: 80px;
    display: block;
    margin: 0 auto 24px;
  }
  .register-dialog-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: @black-text-secondary-V2;
    display: block;
    word-wrap: break-word;

    p {
      text-align: center !important;
    }
  }
  .claimImg {
    width: 400px;
    height: 175px;
    display: block;
    position: relative;
    left: -24px;
    margin-top: 20px;
  }
  .register-claim-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    color: @black-text-secondary-V2;
    display: block;
    word-wrap: break-word;
  }
  .register-flexible-wrapper {
    // background: linear-gradient(to bottom, @theme-white, @theme-white, rgba(248, 199, 27, 0.06));
    .header {
      background: @theme-white;
      width: 100%;
      .nav-menu .subnav.language-unit {
        width: unset;
      }
    }
    .w1000 {
      width: 1000px;
    }

    .fixed-element {
      position: fixed;
      top: 65px;
      right: 0;
      width: 100%;
      z-index: 1000;
    }
    .pr();
    // width: 1920px;
    // height: 100vh;
    min-height: 1100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    // background: url(~assets/img/welfare/register-bg.svg) right bottom no-repeat;
    background-size: 100%;

    .reg_nav {
      height: 64px;
    }
    .logo-title {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      height: 20px;
      position: absolute;
      top: 18px;
      left: 40px;

      .logo-weex {
        width: 115px;
        height: 24px;
      }
    }

    .footer {
      width: 245px;
      height: 16px;
      font-size: 12px;
      color: @gray-P1;
      line-height: 16px;
      margin: 0 auto;
    }

    .main-body {
      // width: 1000px;
      margin: 44px auto 0;
      display: flex;
      justify-content: space-between;
      flex: 1;
      position: relative;
    }

    .go-login {
      margin-top: 16px;
      color: @black-text-tertiary-V2;
      .regular-font();
      a {
        color: @black-text-brand-V2;
        cursor: pointer;
      }
    }

    .left-part-main {
      display: block;
      width: 456px;
      // margin-top: 66px;
      // &.en {
      //   width: 423px;
      // }

      .left-part-title {
        font-size: 36px;
        color: @yellow-P3;
        font-weight: 500;
        .medium-font();
        line-height: 50px;
        &.en {
          //  font-size: 30px;
          //  line-height: 38px;
        }

        span {
          padding: 0px;
          color: @text-black;
          font-size: 30px;
          font-weight: 600;
        }
      }

      .left-part-subtitle {
        margin-top: 16px;
        font-size: 18px;
        color: @text-black;
        line-height: 25px;
        font-weight: 500;

        span {
          color: #000;
          font-weight: 500;
          .medium-font();
        }
      }

      img {
        width: 100%;
        margin-bottom: 24px;
        &.mt {
          margin-top: 24px;
        }
      }
    }

    .right-part-main {
      width: 480px;
      //height: 680px;
      box-sizing: border-box;
      // background: @theme-white;
      //background: rgba(@gray-P1-alpha5);
      .right-part-top {
        border-radius: 12px;
      }
      .right-part-tg {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;
        background: @black-fill-card1-V2;
        border-radius: 24px;
        padding: 12px 16px;
        cursor: pointer;
        .part-tg-left {
          display: flex;
          align-items: center;
          width: 408px;
          color: @black-text-tertiary-V2;
          font-size: 12px;
          line-height: 16px;
        }
        .top-title {
          color: @text-black;
          font-size: 14px;
          line-height: 20px;
          .medium-font();
          font-weight: 500;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 4px;

          .recommend-ico {
            display: inline-block;
            width: 32px;
            height: 16px;
            margin-left: 8px;
          }
        }
        .tg-icon {
          width: 32px;
          height: 32px;
          margin-right: 16px;
        }
        .tg-jiantou {
          width: 24px;
          height: 24px;
        }
      }
      .register-step__1 {
        padding: 40px 24px;
        border-radius: 24px;

        .register-step-title {
          font-size: 30px;
          .bold-font();
          font-weight: 700;
          color: @black-text-primary-V2;
          line-height: 60px;
          .yellow {
            color: @black-text-brand-V2;
          }
        }

        .register-step-subtitle {
          margin-top: 7px;
          font-size: 14px;
          color: @text-black;
          line-height: 19px;
          .medium-font();
        }

        .type-tab {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-top: 40px;
          margin-bottom: 16px;

          span {
            font-size: 16px;
            font-weight: 500;
            color: @black-text-tertiary-V2;
            line-height: 24px;
            .medium-font();
            cursor: pointer;

            &.active {
              //border-bottom: 2px solid @yellow-P3;
              color: @text-black;
            }
          }

          .type-tab-split {
            width: 1px;
            height: 16px;
            // background: #f4f4f4;
            margin: 0 12px;
          }
          // 中文部份
          .fire_icon {
            width: 0.9rem;
            // height: 0.75rem;
            margin-left: 5.2rem;
            display: none;
          }
          .event_title {
            display: none;
            height: 1rem;
            margin-left: 0.15rem;
            font-size: 14px;
            //font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: @yellow-P3;
            line-height: 1rem;
            text-decoration: underline;
          }
          //英文部份
          .fire_icon_en {
            width: 0.9rem;
            // height: 0.75rem;
            margin-left: 1.2rem;
          }
          .event_title_en {
            height: 1rem;
            margin-left: 0.15rem;
            font-size: 13px;
            //font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: @yellow-P3;
            line-height: 1rem;
            white-space: nowrap;
            text-decoration: underline;
          }
        }

        .reg-protocol {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-top: 24px;
          font-size: 14px;
          line-height: 20px;

          .check-protocol {
            height: 20px;
          }

          .check-style {
            margin-right: 10px;
          }

          a {
            .medium-font();
            color: @black-text-brand-V2;
          }
        }
        .register_btn_wrap {
          position: relative;
          margin-top: 24px;
          .re_activity_wrap {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            padding: 0 10px;
            right: 0;
            top: -26px;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 500;
            color: @yellow-P3;
            .medium-font();
            background: rgba(248, 199, 27, 0.2);
            .libao_wrap {
              width: 12px;
              height: 12px;
              margin-right: 9px;
            }
          }
        }
        .reg-btn {
          display: inline-block;
          font-size: 16px;
          font-weight: 500;
          .medium-font();
          width: 432px;
          height: 48px;
          border-radius: 16px !important;
          color: @text-black;
          cursor: pointer;
          text-align: center;
          //font-family: Microsoft YaHei;
        }

        .thirdParty-register {
          padding: 30px 0px 0px;
          display: none;
          .title {
            height: 20px;
            display: flex;
            justify-content: space-between;
            .line {
              width: 124px;
              height: 1px;
              background: rgba(112, 122, 138, 0.2);
              margin-top: 10px;
            }
            .sub-tit {
              text-align: center;
              line-height: 20px;
              font-size: 14px;
              font-weight: 400;
              color: @text-black;
            }
          }
          .btn-wrap {
            display: flex;
            justify-content: space-between;
            margin-top: 15px;
            .btn {
              width: 180px;
              height: 46px;
              background: rgba(112, 122, 138, 0.2);
              border-radius: 6px;
              display: flex;
              font-size: 16px;
              font-weight: bold !important;
              // line-height: 4.4rem;

              .title {
                height: 21px;
                margin-top: 12px;
                .medium-font();
              }
              .icon {
                width: 22px;
                height: 22px;
                &.google {
                  margin: 12px 10px 12px 49px;
                }
                &.apple {
                  margin: 12px 10px 12px 53px;
                }
              }
            }
          }
        }
        .reg-from-error {
          color: @red-color;
        }
      }
    }
    .register-account-row {
      // width: 390px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // background: @gray-P1-alpha10;
      border-radius: 6px;
      position: relative;
      box-sizing: border-box;
      margin-bottom: 24px;
      .area-code-list {
        .sel-area__code {
          .search {
            input {
              box-sizing: border-box;
              width: 100%;
            }
          }
        }
      }
      .clear {
        width: 14px;
        position: absolute;
        right: 17px;
        cursor: pointer;
      }

      .area-code {
        width: 97px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: @black-bg-input-V2;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        // margin-right: 20px;
        position: static;
        &.focus {
          border: 0px;
        }
        .area-code__display {
          font-size: 14px;
          font-weight: 500;
          .medium-font();
          color: @text-black;
          line-height: 21px;
          padding-left: 16px;
          cursor: pointer;

          .icon_down {
            width: 8px;
            height: 8px;
            margin-left: 10px;
          }

          // .iconfont {
          //   font-size: 12px;
          //   color: @gray-P1;
          //   .iconImg{
          //     height: 22px;
          //     width: 22px;
          //       &.eyeopen{
          //           height: 20px;
          //           width: 20px;
          //           background-image: url(~assets/img/new_index/icon_eye_open.svg);
          //           background-size: 100% auto;
          //           background-repeat: no-repeat;
          //           background-position: center;

          //       }
          //       &.eyeclose{
          //           height: 20px;
          //           width: 20px;
          //           background-image: url(~assets/img/new_index/icon_eye_close.svg);
          //           background-size: 100% auto  ;
          //           background-repeat: no-repeat;
          //           background-position: center;
          //       }
          // }
          // }
        }

        .area-code__list {
          position: absolute;
          left: 0;
          top: 62px;
          z-index: 4;
          width: 412px;
          overflow: hidden;
          border-radius: 20px;

          .area-phone-content {
            border-radius: 20px;
          }
        }

        .area-code-list {
          position: absolute;
          left: 0;
          top: 60px;
          z-index: 4;
          width: 100%;
          box-sizing: border-box;
          background: #ffffff;
          box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.16);
          border-radius: 12px;
          padding: 16px;
        }

        .code_line {
          width: 1px;
          height: 20px;
          background: rgba(112, 122, 138, 0.3);
          position: absolute;
          left: 89px;
        }
      }

      .phoneInput {
        width: 336px;
        .register-input__wrapper {
          width: unset;
          border: unset;
          //border-radius: unset;
          border-top-left-radius: unset;
          border-bottom-left-radius: unset;
        }
      }
    }

    .phoneNumber {
      width: 44px;
      height: 19px;
      font-size: 14px;
      .medium-font();
      color: @text-black;
      line-height: 19px;
      margin-top: 30px;
      padding-left: 7px;
      margin-bottom: 3px;
    }

    .confirmPwd {
      // width: 58px;
      height: 19px;
      font-size: 14px;
      .medium-font();
      color: @text-black;
      line-height: 19px;
      margin-top: 33px;
      padding-left: 7px;
      margin-bottom: 3px;
    }

    .common-email-postfix {
      position: relative;
      top: -17px;
      z-index: 100;
      width: 100%;
      background: @black-fill-popup1-V2;
      box-shadow: 0px 1px 1px 0px #00000040;
      border-radius: 12px;
      .item {
        width: 432px;
        line-height: 40px;
        &:hover {
          background: #2d2d2d;
          // border-radius: 12px;
        }
        .input {
          color: @black-text-tertiary-V2;
          padding-left: 16px;
        }
      }
    }

    .password-input__row {
      position: relative;
      // background: #F5F5F5;
      margin-bottom: 24px;
      border-radius: 16px;
      // width: 372px;
      // height: 52PX;
      &.error_hint {
        margin-bottom: 55px;
      }
      &.error_hint2 {
        margin-bottom: 42px;
      }

      .password-input__tip {
        position: absolute;
        top: 55px;
        right: 0;
        z-index: 4;
        width: 300px;
        padding: 12px;
        font-size: 12px;
        line-height: 20px;
        color: @text-white;
        background-color: @black-fill-popup1-V2;
        box-shadow: 0px 4px 4px 0px #00000040;
        border-radius: 16px;
        // display: none;
        .tip2 {
          color: @black-text-tertiary-V2;
          margin-left: 16px;
        }
        .dot {
          position: relative;
          top: -1px;
          width: 6px;
          height: 6px;
          border-radius: 6px;
          background: @black-text-tertiary-V2;
          display: inline-block;
          margin-right: 10px;
          &.green {
            background: @black-text-succcess-V2;
          }
        }
      }

      &:hover {
        .password-input__tip {
          //display: inline-block;
        }
      }

      .password-input-suffix {
        .pa();
        right: 16px;
        top: 16px;
        z-index: 2;

        > .iconfont {
          color: @gray-P1;
          font-size: 14px;
        }
        .clear {
          width: 14px;
          position: absolute;
          right: 24px;
        }
      }

      .password-input-suffix2 {
        .pa();
        right: 20px;
        top: 22px;
        z-index: 2;

        > .iconfont {
          color: @gray-P1;
          font-size: 14px;
        }
      }
    }

    //邀請碼(VIP code)
    .vipCodeTitle {
      font-size: 14px;
      font-weight: 500;
      .regular-font();
      color: @text-black;
      line-height: 20px;
      // margin-top: 20px;
      img {
        width: 10px;
        height: 10px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .vip-code {
      position: relative;
      border-radius: 6px;
      margin-top: 10px;
      .vip-input-suffix {
        .pa();
        right: 20px;
        top: 16px;
        z-index: 2;
      }
    }

    .register-step__2 {
      //margin-top: 120px;
      padding: 24px;
      border-radius: 24px;
      .reg-title {
        font-size: 24px;
        line-height: 28px;
        font-weight: 500;
        color: @text-black;
        .medium-font();
      }

      // 输入的展示
      .input-info {
        width: 100%;
        margin-top: 24px;
        font-size: 14px;
        font-weight: 500;
        color: @black-text-tertiary-V2;
        .regular-font();
        line-height: 16px;
        .black {
          //.regular-font();
        }
      }

      .reg-from-error {
        .pr();
        margin-top: 9px;
        padding-top: 3px;
        font-size: 12px;
        color: @red-color;

        .error {
          .pa();
          top: -8px;
        }

        &.vali-code {
          position: unset;

          .error {
            position: unset;
          }
        }
      }

      .countdown-info {
        margin-top: 32px;
        margin-bottom: 40px;
        font-size: 14px;
        line-height: 20px;
        color: @black-text-tertiary-V2;
      }

      .resend-code {
        line-height: 20px;
        margin-top: 32px;
        margin-bottom: 40px;
        font-size: 14px;
        color: @black-text-brand-V2;
        cursor: pointer;
      }

      // 注册按钮
      .reg-btn {
        display: block;
        width: 388px;
        height: 46px;
        text-align: center;
        font-size: 16px;
        color: @text-black;
        background: @yellow-P1;
        border-radius: 6px;
        border: none;
        outline: none;
        transition: all 0.35s;
        .medium-font();

        &:hover {
          background: @yellow-P2;
        }
      }
    }

    .register-step__3 {
      min-height: 660px;
      text-align: center;
      .img-top-wrap {
        text-align: center;
        margin-bottom: 32px;
        img {
          width: 160px;
          height: 160px;
        }
      }
      .register_success_wrap {
        line-height: 56px;
        font-weight: 500;
        font-size: 40px;
        color: @text-black;
        .medium-font();
      }
      .register_success_tip {
        .medium-font();
        line-height: 24px;
        font-weight: 500;
        font-size: 20px;
        margin: 16px auto 48px;
        color: @text-black;
      }
      .bottom-link-wrap {
        font-weight: 400;
        font-size: 14px;
        color: @gray-P1;
        line-height: 18px;
        a {
          color: @yellow-P3;
        }
      }
      .reg-complete-title {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        font-size: 36px;
        line-height: 48px;
        color: @text-black;
        .medium-font();

        img {
          float: left;
          width: 48px;
          height: 48px;
          margin-right: 20px;
        }

        span {
          float: left;
          width: 532px;
          font-size: 36px;
          line-height: 48px;
          color: @text-black;
        }
      }

      .reg-complete-text {
        margin-left: 68px;
        margin-top: 25px;
        font-size: 16px;
        color: @text-black;
      }

      .reg-complete-go {
        margin-left: 68px;
        margin-top: 38px;
        font-size: 16px;
        color: @text-black;

        a {
          margin-left: 16px;
          color: @yellow-P3;
        }
      }
    }

    .isError {
      box-sizing: border-box;
      border-radius: 16px;
      border: 1px solid @red-color;
      margin-bottom: 40px;
      height: 48px;
    }

    .isActive {
      box-sizing: border-box;
      border-radius: 16px;
      border: 1px solid @black-border-hover-V2;
      height: 48px;
    }

    .icon-lear {
      background-image: url('~assets/img/public/clear.jpg');
      width: 14px;
      background-size: 100%;
    }

    .iconImg {
      width: 14px;
      height: 14px;
      cursor: pointer;
      &.eyeclose {
        width: 14px;
        height: 14px;
        background-image: url(~assets/img/public/icon_eye_close.svg);
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.eyeopen {
        width: 14px;
        height: 14px;
        background-image: url(~assets/img/public/icon_eye_open.svg);
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
  &.black {
    //background:  @black-bg-primary-V2;
    .register-flexible-wrapper {
      .header {
        background: @black-bg-primary-V2 !important;
        .nav {
          background: @black-bg-primary-V2;
          height: 64px;
        }
        /*.nav-menu .subnav.language-unit {
          //background: @black-fill-popup1-V2;
          .subnav-item {
            li {
              &:hover {
                color: @black-text-brand-V2;
              }
                &.active {
                color: @black-text-brand-V2;
              }
            }
          }
        }*/
      }
      // background: linear-gradient(to bottom, @theme-black, @theme-black, rgba(248, 199, 27, 0.06));
      .right-part-main {
        //background: @theme-black;
        .right-part-tg {
          background: @black-fill-card1-V2;
          .top-title {
            color: @black-text-primary-V2;
          }
        }
        .register-step__1 {
          background: @black-fill-card1-V2;
          .register-step-title {
            color: @black-text-primary-V2;
          }
          .type-tab {
            span {
              &.active {
                color: @black-text-primary-V2;
              }
            }
          }
          .reg-protocol {
            color: @black-text-tertiary-V2;
          }
          .thirdParty-register {
            .title {
              color: @text-white;
              .sub-tit {
                color: @text-white;
              }
            }
          }
        }
        .register-step__2 {
          background: @black-fill-card1-V2;
          .reg-title {
            color: @black-text-primary-V2;
          }
          .input-info {
            .black {
              color: @black-text-primary-V2;
            }
          }
          .reg-btn {
            width: 100%;
            height: 48px;
            border-radius: 16px;
          }
        }
      }
      .register-step__3 {
        .register_success_wrap {
          color: @text-white;
        }
        .register_success_tip {
          color: @text-white;
        }
        .reg-complete-title {
          span {
            color: @text-white;
          }
        }
        .reg-complete-text {
          color: @text-white;
        }
        .reg-complete-go {
          color: @text-white;
        }
      }
      .left-part-main .left-part-subtitle {
        color: @text-white;
      }
      .vipCodeTitle {
        color: @black-text-secondary-V2;
      }
      .register-account-row .area-code {
        .area-code__display {
          color: @text-white;
        }
        .area-code-list {
          background: @black-fill-popup1-V2;
          box-shadow: 0px 4px 4px 0px #00000040;
          .sel-area__code .search input {
            background: @black-fill-card2-V2;
            //border: 1px solid @yellow-P3;
            color: @text-white;
            background-image: url('~assets/img/areaCode/sel_w.svg');
            background-size: 16px;
            background-repeat: no-repeat;
            background-position: 98% center;
            :focus {
              background: @black-fill-card2-V2;
            }
          }
          .sel-area__code .search .data .data-list {
            .title {
              color: @text-white;
            }
          }
        }
      }
      .register-input__wrapper {
        //width: 432px;
        //height: 48px;
        //background: @black-bg-input-V2;
        // border-radius: 16px;
        &.focus {
          // border-color: @black-border-hover-V2;
        }
        .register-input__label {
          //line-height: 48px;
          // color: @black-text-tertiary-V2;
        }
        .error-message {
          //top: 58px;
        }
      }
      .register-input__inner input {
        //color: @black-text-primary-V2;
        //background: unset;
      }
    }
  }
}

.onlineBox {
  left: 0px;
  position: absolute;
  top: 0px;
  cursor: pointer;

  .onlineBox1 {
    left: 5px;
  }

  // .onlineBox2 {

  // }
}

.ul-css {
  border: 1px solid #eaecef;
  border-radius: 5px;
  margin: 0;
  overflow: scroll;
  background: #fff;
}

.overseas-sms-msg {
  color: @red-color;
  margin-top: 8px;
}

input:-webkit-autofill {
  -webkit-box-shadow: unset !important;
}

@media screen and (min-width: 992px) {
  .register_bg {
    .left-part-main {
      // margin-right: 64px;
    }
  }
}
@media screen and (min-width: 1200px) {
  .register_bg {
    .left-part-main {
      margin-right: 64px;
    }
    .register_btn_wrap {
      margin-top: 24px;
    }
  }
}
@media screen and (max-width: 992px) {
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 16px;

    &:focus {
      background: unset;
      outline: none;
    }
  }
  .register_bg {
    width: 100%;
    overflow: hidden;
    .register-flexible-wrapper {
      .register-account-row {
        .phoneInput {
          width: 290px;
        }
      }
      .logo-title {
        left: 10px;
      }
      .left-part-main {
        width: 100%;
      }
      .main-body {
        padding: 0 16px;
        flex-direction: column-reverse;
        margin-top: 20px;
        .container-wrap {
          margin-top: 20px;
          .default-wrap-height {
            width: 100%;
            margin-top: 30px;
            img {
              height: auto;
            }
          }
        }
      }
    }
  }

  .register_bg {
    .register-flexible-wrapper {
      height: auto;
      .main-body {
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
      }
      .right-part-main {
        width: 100%;
        .right-part-tg {
          .top-title {
            font-size: 14px;
          }
        }
        .register-step__1 {
          background: transparent !important;
          .reg-btn {
            width: 100%;
          }
        }
        .register-step__1 {
          background: transparent;
          padding: 0;
          .register-step-title {
            font-size: 28px;
            line-height: 33px;
          }
          .type-tab {
            margin-top: 32px;
          }
          .reg-btn {
            width: 100%;
          }
        }
        .register-step__2 {
          padding: 0;
          padding-top: 20px;
          background: transparent !important;
          .input-info {
            width: 100%;
            margin-top: 20px;
          }
          .reg-btn {
            width: 100%;
            height: 44px;
            border-radius: 12px;
          }
          .resend-code {
            margin: 20px auto;
          }
          .countdown-info {
            margin: 20px auto 30px;
          }
        }
        .right-part-tg {
          margin-top: 24px;
          padding: 12px 16px;
        }
      }
      .register-account-row {
        .rejister-wrap {
          width: 100%;
        }
        .area-code {
          .code_line {
            left: 78px;
          }
          .area-code-list {
            .sel-area__code {
              .search {
                input {
                  // background-position: 316px !important;
                }
              }
            }
          }
        }
      }
      .register-account-row {
        width: 100%;
      }
      .register-input__wrapper {
        width: 100%;
        .register-input__inner {
          width: 100%;
          input {
            width: 82%;
            -webkit-text-size-adjust: 100%;
            font-size: 16px;
          }
        }
        .register-input__label {
          width: 100%;
        }
      }
      .phoneInput {
        flex: 1;
        width: auto;
        .register-input__wrapper {
          padding-left: 0;
          .register-input__inner {
            width: 100%;
            input {
              width: 82%;
              -webkit-text-size-adjust: 100%;
              font-size: 16px;
            }
          }
        }
      }
      .password-input__row {
        &.error_hint {
          margin-bottom: 70px;
        }
        .password-input__tip {
          width: 271px;
          top: 55px;
          border-radius: 12px;
        }
      }
      .go-login {
        margin-top: 12px;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 40px;
      }
    }
  }
  .register-success-dialog {
    .el-dialog {
      width: calc(100% - 32px) !important;
    }
    .el-dialog__header {
      padding: 24px 24px 12px !important;
      .el-dialog__headerbtn {
        width: 16px !important;
        height: 16px !important;
        top: 26px !important;
        right: 20px !important;
      }
    }
    .el-dialog__body {
      padding: 0 20px 20px !important;
    }
    .el-dialog__footer {
      padding: 0px 16px 28px !important;
      .dialog-footer {
        display: flex;
        justify-content: space-between;
      }
      button {
        width: 42.5% !important;
        height: 44px !important;
        border-radius: 12px !important;
        padding: 14px 20px !important;
      }
      .el-button--primary {
        padding: 12px 6px !important;
      }
    }
  }
  .claimImg {
    width: 110% !important;
    height: 100% !important;
    display: block;
    position: relative;
    left: -14px !important;
    margin-top: 20px;
  }
}
</style>
